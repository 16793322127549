/**
 * Created by mac on 12/23/20
 */

var GeneratorView = cc.Node.extend({
    ctor: function (generator, unitView) {
        this._super();

        this.unitView = unitView;
        this.generator = generator;

        var styles = cleverapps.styles.GeneratorView;

        this.guideButton = new cleverapps.UI.HelpButton({
            callback: function () {
                cleverapps.focusManager.display({
                    focus: "GeneratorGuideWindow",
                    action: function (f) {
                        new GuideWindow(GeneratorGuideOptions, generator.unit);
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }
                });
            } 
        });
        this.guideButton.setPositionRound(styles.guideButton);
        this.addChild(this.guideButton);

        if (cleverapps.gameModes.hideGeneratorGuideButton) {
            this.guideButton.setVisible(false);
        }
    },

    updateStage: function (newUnit) {
        this.clearAnimations();
        this.updateStageAction = this.unitView.runAction(StandartAnimations.upgrade(this.unitView, newUnit.onGetView(), {
            cloud: true
        }));
    },

    clearAnimations: function () {
        if (this.updateStageAction && !this.updateStageAction.isDone()) {
            this.unitView.stopAction(this.updateStageAction);
        }
    }
});

cleverapps.styles.GeneratorView = {
    cloud: {
        x: 84,
        y: 70,
        scale: 1.35
    },

    guideButton: {
        x: { align: "right", dx: 100 },
        y: { align: "top", dy: 120 }
    }
};